<template>
<div>
<b-row>
<b-col
    cols="12"
   
    lg="8"
    md="7"
>
    <b-card>
        <b-row>
            <b-col
        cols="12"
        xl="4"
        class="d-flex justify-content-between flex-column"
      >
      <img v-if="property.property_images" :src="property.property_images[0].fullPathImg" />
    </b-col>
      <b-col
        cols="12"
        xl="8"
      >
      <h3>{{ property.place_name }}</h3>
      <b>{{ property.bedroom }}</b> Bed
      <b>{{ property.bathroom }}</b> Bath
      <b>{{ property.floors }}</b> Floor
      <b>{{ property.sqm }}</b> sqm.
      <b-badge v-if="property.action_id == 1" variant="light-secondary">
            {{ property.action_name }}
          </b-badge>
          <b-badge v-if="property.action_id == 2" variant="light-info">
            {{ property.action_name }}
          </b-badge>
           <b-badge v-if="property.action_id == 3" variant="light-success">
            {{ property.action_name }}
          </b-badge>
          <b-badge v-if="property.action_id == 4" variant="light-warning">
            {{ property.action_name }}
          </b-badge>
          <b-badge v-if="property.status_id == 1" variant="light-success">
            {{ property.status_code }}
          </b-badge>
          <b-badge v-else variant="light-secondary">
            {{ property.status_code }}
          </b-badge>
          <b-row>
            <b-col cols="6">
                Sale
                {{ property.price_sale | getcomma }} บาท
            </b-col>
            <b-col cols="6">
                Rent
                {{ property.price_rent | getcomma }} บาท

            </b-col>
          </b-row>
    </b-col>
        </b-row>

    </b-card>
</b-col>
<b-col
    cols="12"
    md="5"
   
    lg="4"
>
   <card-agent />
</b-col>
</b-row>
<b-card>
    <b-table
        striped
        hover
        responsive
        
        :items="enquiries"
        :fields="fields"
        show-empty
        empty-text="No matching records found"
        
        
        
    >
    <template #cell(code)="data">
            {{ data.item.code }}<br />
            

           

        </template>
        <template #cell(name)="data">
            {{ data.item.firstname }} {{ data.item.lastname }} <br />
            Email: {{ data.item.email }} <br />
            Phone: {{ data.item.phone }}

        </template>
        <template #cell(detail)="data">
            Looking for : {{ data.item.looking }}<br />
            {{ data.item.title }} ({{ data.item.place_name }})<br />
            {{ data.item.bedroom }} Bedroom<br />
            Budget {{ data.item.rent_min }} - {{ data.item.rent_max }}<br />
            Area : {{ data.item.zone_name }}
            
            
        </template>
    </b-table>
</b-card>
</div>
</template>
<script>
import { ref, onUnmounted } from '@vue/composition-api'

import router from '@/router'

import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormCheckbox,BCard,BBadge,BTable
} from 'bootstrap-vue'
import BCardActions from '../../@core/components/b-card-actions/BCardActions.vue'
import Ripple from 'vue-ripple-directive'
import {
  required, email, confirmed, url, between, alpha, integer, password, min, digits, alphaDash, length,
} from '@validations'
import store from '@/store'
import axios from '@axios'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { methods } from 'vue-echarts'
import CardAgent from './card/card-agent.vue'
//import propertyStoreModule from '../propertiesStoreModule'   
export default {
    components: {
        ValidationProvider,ValidationObserver,
        BFormInput, BFormGroup, BForm, BRow, BCol, BButton,BFormCheckbox,BCard,BBadge,BTable,
        BCardActions,
        CardAgent
    },
    filters: {
        getcomma: function(text){
        if(!text){
            return ''

        }
                var nStr = text;
                nStr += '';
                var x = nStr.split('.');
                var x1 = x[0];
                var x2 = x.length > 1 ? '.' + x[1] : '';
                var rgx = /(\d+)(\d{3})/;
                while (rgx.test(x1)) {
                    x1 = x1.replace(rgx, '$1' + ',' + '$2');
                }
                return x1 ;
            },
    },
    data(){
        return {
            userData: JSON.parse(localStorage.getItem('userData')),
            code:router.currentRoute.params.code,
            property:{},
            fields:[
            {
                key: 'code', label: 'Enquiry Code',
            },
            {
                key: 'detail', label: 'Detail',
            },
            {
                key: 'name', label: 'Agency',
            },
           
           
            ],
            enquiries:[]
        }
    },
    created(){
        console.log('userData', this.userData);
        this.getProperty();
        this.getEnquiry();
    },
    methods:{
        async getProperty(){
            let property = await axios.get('property/v1/bycode/'+this.code);
           this.property = property.data.data;
           console.log('property',this.property);

        },
        async getEnquiry(){
            let filter = {
                perpage: 10,
                offset:0,
            }
            let data = JSON.stringify(filter);
            let enq =  await axios.post('/enquiry/v1/lists', data);
            console.log('enq',enq);
            this.enquiries = enq.data.data;

        }

    }
}
</script>